
.MuiAppBar-positionStatic { /*using for AppBar*/
    background: rgb(41, 40, 40) !important;
    background-Color: rgb(26, 13, 13);
    background-image: linear-gradient(to right bottom, #1C1911, #371A1A);
}

.MuiSvgIcon-root {
    color: tomato !important
}

.menuSliderContainer {
    width: 14rem;
    background-color: #511;
    height: 50rem;
}

.avatar {
    display: block;
    margin-top: 20px;
    margin-left: 20%;
    width: 150px !important;
    height: 150px !important;
}

.avatar:hover{
    box-shadow: 0px 5px rgba(0, 0, 0, 0.8);
}

@media (max-width: 770px) {
    .avatar {
        display: block;
        margin-top: 20px;
        width: 150px !important;
        height: 150px !important;
        margin-left: 15%;
    }

    .menuSliderContainer {
        width: 200px;
        background-color: #511;
        height: 60rem;
    }
  }