@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);

body {
  font-family: 'roboto';
  font-weight: 40px;
  background: 
  /* On "top" */
  repeating-linear-gradient(
    45deg,
    transparent,
    transparent 4px,
    #171717 4px,
    black 8px
  ),
  /* on "bottom" */
  linear-gradient(
    to bottom,
    #171717,
    black
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.outer {
  z-index: 4;
  position: absolute;
  display: inline-block;
  left: 40%;
  margin-top: 2px;
  /* margin-left: -90px; */
  height: 60px;
  width: 300px;
  /* border: 1px solid rgba(207, 124, 14, 0.9); */
  border-radius: 50%;
  background: 
  linear-gradient(
    to bottom, 
    rgba(253,155,18,.8) 0%, 
    rgba(255,42,4,.8) 73%, 
    rgba(253,125,45,.8) 100%
  );
  box-shadow:
    0px 0px 31px 2px rgba(255,119,3,.9), 
    inset 0px 0px 31px 2px rgba(255,119,3,.9);
}

.outer::after {
  content: '';
  /* position: absolute; */
  z-index: 4;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 
    0px 0px 100px 2px rgb(214, 104, 7), 
    inset 0px 0px 100px 2px rgba(255,119,3,1);
  animation: shadowFade 4s infinite ease-in;
}

.most-inner {
  text-align: center;
  position: relative;
  margin-top: 0px;
  /* left: -120px; */
}

.time {
  color: white;
  font-size: 50px;
  position: relative;
  /* margin-left: 170px; */
  display: block;
  margin-top: 0px;
  text-shadow: 
    0 0 5px rgb(224, 217, 217), 
    0 0 10px rgb(231, 228, 228), 
    0 0 7px rgb(124, 118, 118), 
    0 0 20px #1e2020, 
    0 0 35px #68ffc8, 
    0 0 40px #68ffc8, 
    0 0 50px #68ffc8, 
    0 0 75px #68ffc8;
}

.amPm {
  position: relative;
  display: block;
  width: 20px;
  margin-left: 250px;
  margin-top: -30px;
  color: white;
  font-size: 12px;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shadowFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.blink {
  animation: blinking .5s infinite
}

@media (max-width: 770px) {
    .outer {
        position: absolute;
        display: inline-block;
        left: 45%;
        margin-top: 0.6rem;
        margin-left: 35px;
        height: 35px;
        width: 95px;
        /* border: 1px solid rgba(207, 124, 14, 0.9); */
        border-radius: 45%;
        background: 
        linear-gradient(
          to bottom, 
          rgba(253,155,18,.8) 0%, 
          rgba(255,42,4,.8) 73%, 
          rgba(253,125,45,.8) 100%
        );
        box-shadow:
          0px 0px 31px 2px rgba(255,119,3,.9), 
          inset 0px 0px 31px 2px rgba(255,119,3,.9);
      }
      
      .outer::after {
        content: '';
        /* position: absolute; */
        margin-top: 0px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 
          0px 0px 100px 2px rgb(214, 104, 7), 
          inset 0px 0px 100px 2px rgba(255,119,3,1);
        animation: shadowFade 4s infinite ease-in;
      }

      .time {
        color: white;
        font-size: 20px;
        position: relative;
        /* margin-left: 170px; */
        display: block;
        /* margin-top: -3px;
        margin-left: 5px; */
        text-shadow: 
          0 0 5px rgb(224, 217, 217), 
          0 0 10px rgb(231, 228, 228), 
          0 0 7px rgb(124, 118, 118), 
          0 0 20px #1e2020, 
          0 0 35px #68ffc8, 
          0 0 40px #68ffc8, 
          0 0 50px #68ffc8, 
          0 0 75px #68ffc8;
      }
      
      .amPm {
        position: relative;
        display: block;
        width: 20px;
        margin-left: 35px;
        margin-top: -5px;
        color: white;
        font-size: 12px;
      }

      .most-inner {
        text-align: center;
        position: relative;
        margin-top: 0px;
        /* left: -120px; */
      }
  }
