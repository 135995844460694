.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

main {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75));
}

.clock {
  transform: scale(.4);
}

@media (max-width: 770px) {
  .App {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
  }
}

